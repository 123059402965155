.HistoryLists{
  margin: 1rem;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.HistoryList{
  margin-bottom: 1rem;
}

.historyContainer{
  height: 100%;
  width: 100%;
  overflow: auto;
}