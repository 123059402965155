.playerDiv{
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
}

.player{
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  border: 1px solid black;
}