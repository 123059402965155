.chessImgBtn{
  background-color: rgba(50, 50, 50, 0.4);
  border-radius: 10px;
  box-shadow: 2px 2px rgba(50, 50, 50, 0.4);
}

.chessImgBtn:hover{
  background-color: rgba(50, 50, 50, 0.6);
  border-radius: 10px;
  box-shadow: 2px 2px black;
}

.chessImgBtn:active{
  background-color: rgba(50, 50, 50, 0.8);
  border-radius: 10px;
  box-shadow: 2px 2px rgba(50, 50, 50, 0);
}

.hiddenBar:hover{
  outline: solid 2px dimgrey;
}