.reversiHistoryDiv{
  border: solid 2px burlywood;
  display: flex;
  align-items: center;
  width: 24rem;
  justify-content: space-between;
}

.reversiHistoryInnerDiv{
  margin: 0.3rem 0.5rem;
  width: 100%;
}

.reversiHistoryInnerDiv p{
  padding: 0;
  margin: 0;
}

.reversiHistoryBtn{
  display: flex;
  flex-direction: column;
  margin: 0 1rem;
}

.reversiHistoryBtn button{
  margin: 0.1rem 0;
}

.reversiContentText{
  width: 100%;
  display: flex;
  justify-content: space-evenly;
}

.reversiContentText span{
  margin-right: 0.5rem;
  box-shadow: 0 0 2px black;
  padding: 0.1rem;
}