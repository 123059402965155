.App{
  text-align: -webkit-center;
  width: 100%;
  height: 100%;
}

html{
  width: 100%;
  height: 100%;
}

#root{
  width: 100%;
  height: 100%;
}

body{
  width: 100%;
  height: 100%;
}

.container{
  width: 100%;
  height: 100%;
}

.gameContainer{
  flex: 1;
  height: calc(100% - 100px);
  display: flex;
  justify-content: center;
}

.hidden{
  display: none;
}

.navbar{
  display: flex;
  justify-content: space-evenly;
  background-color:silver;
  overflow: auto;
  height: 64px;
}

@media only screen and (max-width: 500px) {
  .navbar{
    justify-content: space-between;
  }
}

.navbar .link{
  color:antiquewhite;
  text-decoration: none;
  text-shadow: darkred 2px 2px 2px;
  height: 100%;
  padding: 0 10px;
  position: relative;
  display: flex;
  align-items: center;
}

.navbar .link:hover{
  background-color:darkgray;
}

.navbar select{
  margin: 1rem 1rem;
}

.floatNavbar{
  position: fixed;
  right: 20px;
  top: 0;
}

.loginBtn{
  padding: 0;
  color:antiquewhite;
  text-decoration: none;
  text-shadow: darkred 2px 2px 2px;
}
