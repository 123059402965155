.square{
  width: 3rem;
  height: 3rem;
  /* background-color: bisque; */
  border: solid 2px rgba(0, 0, 0, 0.5);
  position: relative;
  padding: 0.25rem;
}

.key{
  width: 90%;
  height: 90%;
  border-radius: 50%;
  background-color: bisque;
  border: solid 2px rgba(0, 0, 0, 0.5);
}

.key:hover{
  background-color: yellowgreen
}
