.CCTitle{
  font-size: 6.5vmin;
}

.CCMap{
  background-image: url('http://www.clker.com/cliparts/2/1/6/b/1197099044862740731antontw_chinese_chess_plate.svg');
  background-repeat: no-repeat;
  /* background-attachment: fixed; */
  background-size: contain;
  background-position: center;
  width: 100%;
  /* height: 100%; */
  display: table;
}

.CCBtn{
  width: 8vmin;
  height: 8vmin;
  padding: 0;
  position: relative;
  border: none;
  margin: 0;
  background-color: rgba(0, 0, 0, 0);
}

.CCBtnMostOuterDiv, .CCBtnMostOuterDiv00, .CCBtnMostOuterDiv01, .CCBtnMostOuterDiv02, .CCBtnMostOuterDiv03, .CCBtnMostOuterDiv04, .CCBtnMostOuterDiv05, .CCBtnMostOuterDiv06, .CCBtnMostOuterDiv07{
  position: relative;
  width: 100%;
  height: 100%;
}

.CCBtnOuterDiv{
  position: relative;
  width: 100%;
  height: 50%;
  display: flex;
}

.CCBtnDiv, .CCBtnDiv00, .CCBtnDiv01, .CCBtnDiv011, .CCBtnDiv02, .CCBtnDiv021, .CCBtnDiv03, .CCBtnDiv031, .CCBtnDiv04{
  position: relative;
  width: calc(50%);
  height: calc(100% - 2px);
  /* border: solid 1px black; */
}

.CCChess, .CCChessblack, .CCChessred, .CCChessgreen{
  top: 0;
  position: absolute;
  width: 90%;
  height: 90%;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 5vmin;
}

.CCChess{
  border: 1px solid rgba(37, 30, 30, 0);
  background-color: rgba(222, 184, 135, 0);
}

.CCChessblack{
  border: 1px solid black;
  background-image: radial-gradient(circle, lightcyan, white, black);
  color: black;
}

.CCChessred{
  border: 1px solid black;
  background-color: black;
  color: red;
}

.CCChessgreen{
  border: 1px solid rgba(255, 255, 255, 0);
  background-color: rgba(153, 205, 50, 0.5);
}

.CCPlayerDiv{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
}

.CCPlayerDiv span{
  font-size: 3vmin;
}

.resetBtn{
  margin-top: 1rem;
}

.CCPlayerBlack, .CCPlayerRed{
  border-radius: 50%;
  width: 5vmin;
  height: 5vmin;
  border: 1px solid black;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 3vmin;
}

.CCPlayerBlack{
  background-image: radial-gradient(circle, lightcyan, white, black);
  color: black;
}

.CCPlayerRed{
  background-color: black;
  color: red;
}